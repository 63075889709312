import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Hero from 'components/hero';
import Container from '@material-ui/core/Container';
import { TitleContentColumn, AllProjects } from 'components/slices';
import { withPreview } from 'gatsby-source-prismic';
import { Helmet } from 'react-helmet';

function Projects(props) {
  const { data } = props;
  const doc = data.prismicProjects;
  if (!doc) return null;
  return (
    <Layout>
      {doc.data.metadata && doc.data.metadata[0] && (
        <Helmet>
          {doc.data.metadata[0].title && doc.data.metadata[0].title[0] && (
            <title>{doc.data.metadata[0].title[0].text}</title>
          )}
          {doc.data.metadata[0].description &&
            doc.data.metadata[0].description[0] && (
              <Helmet>
                <meta
                  name="description"
                  content={doc.data.metadata[0].description[0].text}
                />
                <meta
                  property="og:description"
                  content={doc.data.metadata[0].description[0].text}
                />
                <meta
                  name="twitter:description"
                  content={doc.data.metadata[0].description[0].text}
                />
              </Helmet>
            )}
          {doc.data.metadata[0].keywords &&
            doc.data.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.data.metadata[0].keywords[0].text}
              />
            )}
        </Helmet>
      )}
      <Hero data={doc.data} />
      {doc.data.body && (
        <Container maxWidth="xl">
          {doc.data.body.map((item, i) => {
            switch (item.slice_type) {
              case 'title__content_col':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <TitleContentColumn data={item.primary} />
                    </div>
                  </div>
                );
              case 'all_projects':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <AllProjects data={item.primary} items={item.items} />
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })}
        </Container>
      )}
    </Layout>
  );
}

Projects.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withPreview(Projects);

export const query = graphql`
  query ProjectsPageQuery {
    prismicProjects {
      data {
        metadata {
          description {
            text
          }
          keywords {
            text
          }
          title {
            text
          }
        }
        page_title {
          html
          raw
          text
        }
        banner_title {
          html
          raw
          text
        }
        banner_image {
          alt
          url
        }
        body {
          ... on PrismicProjectsBodyTitleContentCol {
            slice_type
            primary {
              title {
                html
                raw
                text
              }
              content {
                html
                raw
                text
              }
              caption {
                html
                raw
                text
              }
            }
          }
          ... on PrismicProjectsBodyAllProjects {
            slice_type
            primary {
              limit
            }
            items {
              status {
                document {
                  ... on PrismicProjectStatus {
                    data {
                      status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
